import React from "react";

const SuccessPopup = ({ onClose }) => {
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button className="close-btn" onClick={onClose}>×</button>
        <h2>🎉 Registration Successful!</h2>
        <p>
          Thank you for registering for the <strong>Free Summer Internship Program 2025</strong>! 🌱
          <br />
          You will be notified about the assessment dates shortly via email. 📧
        </p>
      </div>

      <style jsx>{`
        .popup-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.6);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }

        .popup-content {
          background: #e6ffe6; /* Light green background */
          padding: 30px;
          border-radius: 15px;
          text-align: center;
          max-width: 400px;
          box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
          position: relative;
          border: 3px solid #4CAF50; /* Darker green border */
          animation: fadeIn 0.5s ease-in-out;
        }

        h2 {
          color: #2e7d32; /* Deep green for heading */
          font-size: 24px;
          margin-bottom: 10px;
        }

        p {
          font-size: 16px;
          color: #388e3c; /* Slightly darker green for text */
          line-height: 1.5;
        }

        .close-btn {
          position: absolute;
          top: 10px;
          right: 15px;
          background:rgb(164, 189, 165);
          color: white;
          border: none;
          border-radius: 50%;
          padding: 5px 10px;
          font-size: 18px;
          cursor: pointer;
          transition: background 0.3s ease;
        }

        .close-btn:hover {
          background: #2e7d32;
        }

        @keyframes fadeIn {
          from {
            opacity: 0;
            transform: scale(0.9);
          }
          to {
            opacity: 1;
            transform: scale(1);
          }
        }
      `}</style>
    </div>
  );
};

export default SuccessPopup;
