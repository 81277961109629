import React from "react";
import ScrollIntoView from "react-scroll-into-view";

function About({ about }) {
  return (
    <section id="AboutSection">
      <div className="container">
        <h3 className="text-center my-5">
        About Our Free Summer Internship  Program 2025
        </h3>
        <div
          className="text-center p-3 border rounded-5  ytdiv"
          style={{ background: "#f4f4f4" }}
        >
          <iframe
            // src="https://www.youtube.com/embed/WJDWXqa9anU" // for winter internship link
            src="https://www.youtube.com/embed/nrZiEpFveaQ" // for summmer free internship link
            // src="https://www.youtube.com/embed/nrZiEpFveaQ?si=bCY52PelTLfJ3SQf" //Thise one summer link
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
            className="YtEmbed rounded-5 "
          ></iframe>
        </div>
        <div className="row justify-content-center mt-5" id ="FeaturesFreeSummer" >
        <h3 className="text-center my-5">
        Features Of Free Summer Internship  Program 2025
        </h3>
          {about.map((item, index) => {
            return (
              <div className="sitpCard col-12 col-md-6 p-4" key={index}>
                <div
                  className=" card-content text-justify rounded p-4 d-flex flex-column justify-content-between"
                  style={{
                    background: "#f4f4f4",
                    boxShadow: "1px 3px 7px rgba(0,0,0,0.5)",
                    height: "100%",
                  }}
                >
                  <div>
                    <h6 className="SITPHeading heading-about-card pb-3">
                      {item.title}
                    </h6>

                    <p className="SITPDesc text-about-card text-justify lh-base">
                      {item.description}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
          <div className="text-center my-5 d-block d-md-flex  gap-5 justify-content-center text-center">
            <ScrollIntoView selector="#formRegister">
              <button className="d-inline-block btn btn-primary px-5 py-3 mx-1">
                Register Now
              </button>
            </ScrollIntoView>
            {/* <div className="d-flex justify-content-center my-3 my-md-0">
              <a
                href="https://www.youtube.com/watch?v=nrZiEpFveaQ"
                target="_blank"
                rel="noopener noreferrer"
                className="d-inline-block btn btn-white border border-2 px-4 py-2 mx-1 d-flex justify-content-center align-items-center"
              >
                <div
                  className="d-flex justify-content-center align-items-center me-3"
                  style={{
                    background: "red",
                    height: 30,
                    width: 30,
                    borderRadius: "50%",
                  }}
                >
                  <img src="assets/play_btn.png" alt="" className="w-25" />
                </div>
                Watch Video
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;







//adding carousel on acceess the youtube video

// import React from "react";
// import ScrollIntoView from "react-scroll-into-view";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";


// function About({ about,YoutubeVideo }) {

  
//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 400,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true,
//     speed: 500,
//     autoplaySpeed: 3000,
//     cssEase: "linear"

//   };
  
//   return (
//     <section id="AboutSection">
//       <div className="container">
//         <h3 className="text-center my-5">
//           About Our Summer Internship Program
//         </h3>
//         <div
//           className="text-center p-3 border rounded-5 ytdiv"
//           style={{ background: "#f4f4f4" }}
//         >
//           <Slider {...settings}>
//             {YoutubeVideo.map((link, index) => (
              
//               <div key={index}>
//                 <iframe
//                   src={link.links}
//                   title={`YouTube video player ${index}`}
//                   frameBorder="0"
//                   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//                   allowFullScreen
//                   className="YtEmbed rounded-5"
//                 ></iframe>
//               </div>
//             ))}
//           </Slider>
//           {console.log({})}
//         </div>
//         <div className="row justify-content-center mt-5">
//           {about.map((item, index) => (
//             <div className="sitpCard col-12 col-md-6 p-4" key={index}>
//               <div
//                 className="card-content text-justify rounded p-4 d-flex flex-column justify-content-between"
//                 style={{
//                   background: "#f4f4f4",
//                   boxShadow: "1px 3px 7px rgba(0,0,0,0.5)",
//                   height: "100%"
//                 }}
//               >
//                 <div>
//                   <h6 className="SITPHeading heading-about-card pb-3">
//                     {item.title}
//                   </h6>
//                   <p className="SITPDesc text-about-card text-justify lh-base">
//                     {item.description}
//                   </p>
//                 </div>
//               </div>
//             </div>
//           ))}
//           <div className="text-center my-5 d-block d-md-flex gap-5 justify-content-center text-center">
//             <ScrollIntoView selector="#formRegister">
//               <button className="d-inline-block btn btn-primary px-5 py-3 mx-1">
//                 Register Now
//               </button>
//             </ScrollIntoView>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }

// export default About;
