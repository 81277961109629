// import React from "react";
import React, { useState, useEffect } from "react";
import Partners from "../Components/Partners";
import data from "../data/lnb_data.json";
import Gallery from "../Components/Gallery";
import Features from "../Components/Features";
import Mentorship from "../Components/Mentorship";
import WhyChoose from "../Components/WhyChoose";
import WhoShouldJoin from "../Components/WhoShouldJoin";
import About from "../Components/About";
import "../Assets/Css/main.css";
import Register from "../Components/Auth/Register";
import GetCertificate from "../Components/GetCertificate";
import RealStory from "../Components/RealStory";
import GetInTouch from "../Components/GetInTouch";
import DownloadApp from "../Components/DownloadApp";
import FAQ from "../Components/FAQ";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
// import VideoGallery from "../Components/VideoGallery";
import ScrollIntoView from "react-scroll-into-view";
import Colleges from "../Components/Colleges";
import Companies from "../Components/Companies";
import Coding from "../Components/Coding";
import Technology from "../Components/Technology";
import Alumni from "../Components/Alumni";
import WAButton from "../Components/WAButton";
import Popup1 from "../Components/Popup/Popup";
import PopupWeb from "../Components/Popup/Popup";
import FooterWithModal from "../Components/FooterSticky";
import Hero from "../Components/Hero";
import Combo from "../Components/Combo";
import { ToastContainer, toast } from 'react-toastify';
import Popup from "../Components/Popup/PopupFreeSummer";
<style>
  @import
  url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap')
</style>;

function Home() {

  const [showPopup, setShowPopup] = useState(false);

  // Show popup when the website loads
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 1000); // 1-second delay after page load

    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <ToastContainer />

      {/* Header */}
      <Header />
      {showPopup && <Popup onClose={() => setShowPopup(false)} />}
      {/* Hero section */}
      <Hero />
      {/* partners */}
      {/* <Partners partners={data.partners} /> */}
      {/* About our summer */}
      <About about={data.about} />
      {/* register */}
      <Register />
      {/* why choose */}
      <WhyChoose />

      <Gallery images={data.gallery} />
      <WhoShouldJoin />
      {/* Coding/Programming */}
      {/* Combos */}
      <Coding Coding={data.Coding} />
      {/* Technology */}
      <Technology technologies={data.Technology} />
      {/* <Combo combo={data.Combo} /> */}

      <div className="container StandOut text-center py-5 mt-5">
        <h3>The Learn and Build Difference: Stand Out in Your Career?</h3>
        <p className="w-100 p-4 learnDiff m-auto mt-3">
          At Learn and Build, we offer a unique learning experience that sets us
          apart from the rest. Our program is designed to equip you with
          real-world skills and knowledge that will help you excel in your
          career.
        </p>
      </div>
      {/* <Features features={data.features} /> */}
      <Mentorship mentors={data.mentors} />
      <GetCertificate images={data.certificates} />
      {/* stories */}
      <RealStory stories={data.stories} />
      {/* Video */}
      {/* <VideoGallery videos={data.videos} /> */}
      {/* Alumni */}
      <Alumni alumnis={data.alumnis} />
      {/* Colleges */}
      <Colleges colleges={data.colleges} />
      {/* Companies */}
      <Companies companies={data.companies} />
      {/* get in touch */}
      <WAButton />
      <GetInTouch />
      {/* <PopupWeb /> */}
      <FAQ />
      <FooterWithModal />
      <DownloadApp />
      <Footer />
    </>
  );
}

export default Home;
