import React, { useEffect, useState } from "react";
import { notification } from "antd";
import CourseFees from "../../data/fees.json";
import "./Register.css";
import SuccessPopup from "../Popup/SuccessPopup";  // Adjust the path if needed


function Register() {
  const [step, setStep] = useState(1);
  const [fees, setFees] = useState();
  const [coursedata, setCoursedata] = useState();
  const [online, setOnline] = useState(null);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const collegeOptions = [
    "Anand International College of Engineering, Jaipur",
    "Arya College of Engineering, Jaipur",
    "Agra College",
    "Arya Institute of Engineering Technology & Management, Jaipur",
    "Amity University",
    "Apex University",
    "Arka Jain University, Jamshedpur",
    "Arya College of Engineering and I.T, Jaipur",
    "Banasthali Vidyapith",
    "Babu Banarasi Das Northern India Institute of Technology, Lucknow",
    "Biyani Group Of Colleges",
    "B K Birla Institute of Engineering & Technology, Pilani",
    "B.S.A. College of Engineering & Technology, Mathura",
    "BITS Pilani Goa campus",
    "BITS Pilani Jaipur campus",
    "Bikaner Technical University (BTU)",
    "CVR College of Engineering, Hyderabad",
    "Dr. D. Y. Patil Vidyapeeth, Pune",
    "Global institute of Technology (GIT), Jaipur",
    "Government Engineering College (GEC), Banswara",
    "Government women engineering College, Ajmer",
    "Government Engineering College, Bharatpur",
    "Jagannath University (JU)",
    "Jaipur Engineering College and Research Centre (JECRE)",
    "Jodhpur Institute of Engineering & Technology, Jodhpur",
    "Jaipur National University (JNU)",
    "Jayoti Vidyapeeth Women’s University (JVWU)",
    "Lovely Professional University (LPU)",
    "Manipal University Jaipur",
    "MITS Pali (Mahaveer Institute of Technology & Science)",
    "Modi University (Dr K.N. Modi University)",
    "M.B.M. Engineering College, Jodhpur",
    "Maharishi Arvind Institute of Science & Management, Jaipur",
    "Malaviya National Institute of Technology, Jaipur",
    "Poornima College of Engineering",
    "Poornima Institute of Engineering and Technology",
    "Poddar College (Poddar Group of Institutions)",
    "Poornima University",
    "Pratap Institute of Technology & Science",
    "Rajasthan College of Engineering for Women (RCEW)",
    "Rajasthan Technical University (RTU), Kota",
    "S. S. Jain [Subodh College (or S. S. Jain Subodh P. G. College)]",
    "Swami Keshvanand Institute of Technology (SKIT)",
    "Stani Memorial P.G College",
    "SRM University, Bhopal",
    "St. Xavier's College, Jaipur",
    "The ICFAI University",
    "University of Engineering & Management, Jaipur",
    "Vivekananda Global University (VGU)",
    "Vyas College of Engineering and Technology, Jodhpur",
    "Vyas Institute of Engineering and Technology, Jodhpur",
    "Other",
  ];
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    whatsapp: "",
    alternateMobile: "",
    collegeName: "",
    otherCollege: "",
    year: "",
    course: "",
    technology: "",
    training_mode: "",
    starting_batch_date: "",
    duration: "",
    hear_about_us: "",
    discount_coupon: "",
    reference_name: "",
    any_comment: "",
    category: "",
  });

  const setTraining_Mode = (e) => {
    console.log(e)
    setFormData({
      ...formData,
      ['training_mode']: e
    });
    // setOnline(e)

  }
  const [filteredCourses, setFilteredCourses] = useState(CourseFees.courseSummerFree); // State for filtered courses

  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    setFormData({ ...formData, category: selectedCategory, technology: '' }); // Reset technology
  };

  useEffect(() => {
    // Filter courses based on selected category
    if (formData.category) {
      const filtered = CourseFees.courseSummerFree.filter(
        (course) => course.category === formData.category
      );
      setFilteredCourses(filtered);
    } else {
      setFilteredCourses(CourseFees.courseSummerFree); // Show all if no category selected
    }
  }, [formData.category]);

  useEffect(() => {

    // console.log("heerss")

    checkCourse(formData.technology)

  }, [formData.technology, formData.training_mode])


  // const [isNotCollege , setIsNotCollege] = useState(  collegeName == "Other" ? true : false)

  const handleSubmit = async (e) => {

    console.log(formData)
    e.preventDefault();

    try {
      const requiredFieldsStep1 = [
        "firstName",
        "lastName",
        "email",
        "whatsapp",
        "collegeName",
        "year",
        "course",
      ];
      let isValidStep1 = true;
      for (const field of requiredFieldsStep1) {
        if (!formData[field]) {
          isValidStep1 = false;
          document.getElementById(field).classList.add("invalid");
        }
      }
      if (!isValidStep1) {
        return;
      }

      // check email

      if (
        !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(formData.email)
      ) {
        notification.error({
          message: "Error",
          description: "Please enter a valid email",
        });
        return;
      }

      if (!/^[6-9]\d{9}$/.test(formData.whatsapp)) {
        notification.error({
          message: "Error",
          description: "Invalid WhatsApp Number",
        });
        return;
      }


      if (formData.technology == "Select Technology" || !formData.technology || formData.technology == "" || formData.technology == 'undefined' || formData.technology == null) {

        notification.error({
          message: "Error",
          description: "Please Select Technology",
        });
        return;
      }
      if (formData.starting_batch_date == "Select Month" || !formData.starting_batch_date || formData.starting_batch_date == "" || formData.starting_batch_date == 'undefined' || formData.starting_batch_date == null) {

        notification.error({
          message: "Error",
          description: "Please Select Training Start Date",
        });
        return;
      }
      if (formData.duration == null || !formData.technology || formData.technology == "" || formData.technology == 'undefined') {

        notification.error({
          message: "Error",
          description: "Please Select Duration",
        });
        return;
      }



      const data = {
        name: formData.firstName + " " + formData.lastName,
        email: formData.email,
        whatsappNo: formData.whatsapp,
        alternativeNo: formData.alternateMobile,
        college: formData.collegeName == "Other" ? formData.otherCollege : formData.collegeName,
        branch: formData.course,
        year: formData.year,
        technology: formData.technology,
        training_mode: formData.training_mode,
        starting_batch_date: formData.starting_batch_date,
        duration: formData.duration,
        hear_about_us: formData.hear_about_us,
        discount_coupon: formData.discount_coupon,
        reference_name: formData.reference_name,
        any_comment: formData.any_comment,
      };

      const response = await fetch(
        "https://internapi.learnandbuild.in/api/auth/signup",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (response.ok) {
        let paymentUrl = "";
        const responseData = await response.json();
        // notification.success({
        //   message: "Success",
        //   description: responseData.message,
        // });


        if (fees && fees.actual === 0) {
          // Clear the form
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            whatsapp: "",
            alternateMobile: "",
            collegeName: "",
            otherCollege: "",
            course: "",
            year: "",
            technology: "",
            training_mode: "",
            starting_batch_date: "",
            duration: "",
            hear_about_us: "",
            discount_coupon: "",
            reference_name: "",
            any_comment: "",
            category: "",
          });

          // Show success message
          notification.success({
            message: "Success",
            description: "Your form has been submitted successfully. Our team will contact you soon.",
          });
          setShowSuccessPopup(true);
          return;
        }


        if (fees && fees.actual) {

          if (fees.actual === 2500) {

            paymentUrl = "https://rzp.io/rzp/WWyGyvD";
          }
          else if (fees.actual === 7500) {
            paymentUrl = "https://rzp.io/rzp/gMtx6Mkv";
          }
          else {
            notification.error({
              message: "Error",
              description: "Invalid fee value",
            });
            return;
          }
          window.location.href = paymentUrl;

        } else {
          const errorData = await response.json();
          notification.error({
            message: "Error",
            description: errorData.message,
          });
        }
      }
    } catch (err) {
      console.log(err);
    }

    console.log("Form Submitted");
  };

  const handleInputChange = (e) => {
    const { id } = e.target;
    document.getElementById(id).classList.remove("invalid-field");

    // Update form data state
    setFormData({ ...formData, [id]: e.target.value });
  };

  const checkCourse = (course) => {
    setFormData({
      ...formData,
      technology: course,
      duration: "",
    });

    // for (let index = 0; index < CourseFees.courseSummerFree.length; index++) {
    //   if (CourseFees.courseSummerFree[index].name === course) {
    //     setCoursedata(CourseFees.courseSummerFree[index]);
    //   }
    // }

    // setFees(null);
    const selectedCourse = filteredCourses.find(item => item.name === course); // Use filteredCourses

    setCoursedata(selectedCourse);
    setFees(null);
  };

  const setDurationAndFees = (duration) => {
    if (formData.training_mode === null || formData.training_mode == "Select Training Mode" || formData.training_mode == "" || !formData.training_mode || formData.training_mode == 'undefined') {
      notification.error({
        message: "Error",
        description: "Please select Training Mode",
      });
      checkCourse(formData.technology)
      return;

    }

    setFormData({ ...formData, duration: duration });
    if (formData.training_mode === "Online") {
      setFees(coursedata.online[duration]);
    } else {
      setFees(coursedata.offline[duration]);
    }
  };

  const saveFirstStepData = async () => {


    if (formData.collegeName == "Other" && !formData.otherCollege) {
      notification.error({
        message: "Error",
        description: "Please Enter College Name",
      });
      // return;
      setStep(1)
    }
    else {

      setStep(2)



      let collegeToSave = formData.collegeName;
      if (formData.collegeName === "Other") {
        collegeToSave = formData.otherCollege;
      }
      const user = {
        name: formData.firstName + " " + formData.lastName,
        email: formData.email,
        whatsappNo: formData.whatsapp,
        alternativeNo: formData.alternateMobile,
        college: collegeToSave,
        branch: formData.course,
        year: formData.year,
      };

      const response = await fetch(
        "https://internapi.learnandbuild.in/api/auth/save-first-step",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(user),
        }
      );
    }
  };

  return (
    <section id="formRegister">
      <h3 className="text-center my-5">Register Now</h3>
      <div className="row m-0 mb-5">
        <div className="col-12 col-md-8">
          <div className="container ps-0 ps-md-5 d-flex justify-content-center pb-5">
            <form
              className="formRegister ps-0 ps-md-0 mb-0 w-75"
              onSubmit={handleSubmit}
            >
              {step === 1 && (
                <>
                  <div className="row">
                    <div className="col-12 col-md-6 mb-3">
                      <div className="form-group mt-3">
                        <label htmlFor="firstName">
                          First Name <span>*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="First Name"
                          required
                          value={formData.firstName}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              firstName: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <div className="form-group mt-3">
                        <label htmlFor="lastName">
                          Last Name <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Last Name"
                          value={formData.lastName}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              lastName: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3 mt-2">
                    <label htmlFor="lastName">
                      Email <span>*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control form-control-lg"
                      placeholder="Email"
                      value={formData.email}
                      onChange={(e) => {
                        setFormData({ ...formData, email: e.target.value });
                      }}
                    />
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 mb-3 mt-2">
                      <div className="form-group">
                        <label>
                          WhatsApp No. <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="WhatsApp No."
                          maxLength="10"
                          value={formData.whatsapp}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              whatsapp: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mb-3 mt-2">
                      <div className="form-group">
                        <label>Alternate Mobile No.</label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Alternate Mobile No."
                          value={formData.alternateMobile}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              alternateMobile: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="form-group mb-3 mt-2">
                        <label>
                          College Name <span>*</span>
                        </label>
                        <select
                          className="form-select form-select-lg"
                          value={formData.collegeName}
                          onChange={(e) => {
                            const selectedCollege = e.target.value;
                            setFormData({
                              ...formData,
                              collegeName: selectedCollege,
                              otherCollege:
                                selectedCollege === "Other"
                                  ? formData.otherCollege
                                  : "",
                            });
                          }}
                        >
                          <option value="" disabled>
                            Select College
                          </option>
                          {collegeOptions.map((college, index) => (
                            <option key={index} value={college}>
                              {college}
                            </option>
                          ))}
                        </select>
                        {formData.collegeName === "Other" && (
                          <div className="mt-3">
                            <label for="otherCollege">
                              Enter Name <span>*</span>
                            </label>
                            <input
                              name="otherCollege"
                              type="text"
                              className="form-control form-control-lg "
                              placeholder="Enter Your College Name"
                              value={formData.otherCollege}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  otherCollege: e.target.value,
                                });
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group mb-3 mt-2">
                        <label>
                          Year <span>*</span>
                        </label>
                        <select
                          className="form-control form-control-lg"
                          value={formData.year}
                          onChange={(e) => {
                            setFormData({ ...formData, year: e.target.value });
                          }}
                        >
                          <option value="">Select Year</option>
                          <option value="1st">1st</option>
                          <option value="2nd">2nd</option>
                          <option value="3rd">3rd</option>
                          <option value="4th">4th</option>
                          <option value="Graduated">Graduated</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <label className="my-3">Course</label>
                    <div className="col d-flex align-items-center">
                      <input
                        type="radio"
                        className="me-2 form-check-input mb-2"
                        id="btech"
                        name="course"
                        value={formData.course}
                        onChange={(e) => {
                          setFormData({ ...formData, course: "B.Tech" });
                        }}
                      />
                      <label className="form-check-label" htmlFor="btech">
                        B.Tech
                      </label>
                    </div>
                    <div className="col d-flex align-items-center">
                      <input
                        type="radio"
                        className="me-2 form-check-input mb-2"
                        id="bca"
                        name="course"
                        value={formData.course}
                        onChange={(e) => {
                          setFormData({ ...formData, course: "BCA" });
                        }}
                      />
                      <label className="form-check-label" htmlFor="bca">
                        BCA
                      </label>
                    </div>
                    <div className="col d-flex align-items-center">
                      <input
                        type="radio"
                        className="me-2 form-check-input mb-2"
                        id="mca"
                        name="course"
                        value={formData.course}
                        onChange={(e) => {
                          setFormData({ ...formData, course: "MCA" });
                        }}
                      />
                      <label className="form-check-label" htmlFor="mca">
                        MCA
                      </label>
                    </div>
                    {/* Others */}
                    <div className="col d-flex align-items-center">
                      <input
                        type="radio"
                        className="me-2 form-check-input mb-2"
                        id="other"
                        name="course"
                        value={formData.course}
                        onChange={(e) => {
                          setFormData({ ...formData, course: "Others" });
                        }}
                      />
                      <label className="form-check-label" htmlFor="other">
                        Others
                      </label>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary btn-lg mt-4 rounded-0"
                    onClick={() => {
                      // Check if all required fields in step 1 are filled out
                      if (
                        formData.firstName &&
                        formData.lastName &&
                        formData.email &&
                        formData.whatsapp &&
                        formData.collegeName &&
                        formData.year &&
                        formData.course
                      ) {
                        saveFirstStepData();
                        // setStep(2); // Move to step 2
                      } else {
                        // Show error message if any required field is missing
                        notification.error({
                          message: "Error",
                          description:
                            "Please fill out all required fields in Step 1.",
                        });
                      }
                    }}
                  >
                    Next
                  </button>
                </>
              )}
              {step === 2 && (
                <>
                  {/* <div className="form-group">
                    <label className="form-label">Select Technology</label>
                    <select
                      className="form-select form-select-lg mb-3"
                      style={{ background: "transparent" }}
                      onChange={(e) => checkCourse(e.target.value)}
                      value={formData.technology}
                    >
                      <option selected>Select Technology</option>
                      {CourseFees.courseSummerFree.map((item, index) => {
                        return (
                          <option key={index} value={item.name}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div> */}
                  <div className="mb-3">
                    <label className="form-label">Select Category</label><br />
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="category"
                        id="coding"
                        value="Coding"
                        checked={formData.category === "Coding"}
                        onChange={handleCategoryChange}
                      />
                      <label className="form-check-label" htmlFor="coding">
                        Coding
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="category"
                        id="programming"
                        value="Programming"
                        checked={formData.category === "Programming"}
                        onChange={handleCategoryChange}
                      />
                      <label className="form-check-label" htmlFor="programming">
                        Programming
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="category"
                        id="technology"
                        value="Technology"
                        checked={formData.category === "Technology"}
                        onChange={handleCategoryChange}
                      />
                      <label className="form-check-label" htmlFor="technology">
                        Technology
                      </label>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="form-label">Select Technology</label>
                    <select
                      className="form-select form-select-lg mb-3"
                      style={{ background: "transparent" }}
                      onChange={(e) => checkCourse(e.target.value)}
                      value={formData.technology}
                    >
                      <option value="">Select </option>
                      {filteredCourses.map((item, index) => ( // Use filteredCourses here
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label className="form-label">Training Mode</label>
                    <select
                      className="form-select form-select-lg mb-3"
                      style={{ background: "transparent" }}
                      onChange={(e) => {

                        setTraining_Mode(e.target.value);
                        // checkCourse(formData.technology);
                      }}
                      value={formData.training_mode}
                    >
                      <option selected value={null} >Select Training Mode</option>
                      {/* {coursedata && coursedata.online && ( */}
                      <option value="Online">Online</option>
                      {/* )} */}
                      {/* {coursedata && coursedata.offline && ( */}
                      {/* <option value="Offline">Offline</option> */}
                      {/* )} */}
                    </select>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          Training Starting From
                        </label>
                        <select
                          className="form-select form-select-lg mb-3"
                          style={{ background: "transparent" }}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              starting_batch_date: e.target.value,
                            });
                          }}
                          value={formData.starting_batch_date}
                        >
                          <option selected>Select Month</option>
                          {/* <option value="May">May</option> NO*/}
                          <option value="April 2025">April 2025</option>
                          <option value="May 2025">May 2025</option>
                          {/* <option value="14 May">14 May</option>
                          <option value="24 May">24 May</option> */}
                          {/* <option value="June">June</option> NO*/}
                          {/* <option value="3 June">3 June</option> */}
                          {/* <option value="14 Dec">14 Dec</option>
                          <option value="23 Dec">23 Dec</option>
                          <option value="6 Jan">6 Jan</option> */}
                          {/* <option value="24 June">24 June</option> NO*/}
                          {/* <option value="July">July</option> NO*/}
                          {/* <option value="1 July">1 July</option>
                          <option value="15 July">15 July</option>
                          <option value="24 July">24 July</option> */}
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label className="form-label">Training Duration</label>
                        <select
                          className="form-select form-select-lg mb-3"
                          style={{ background: "transparent" }}
                          onChange={(e) => {
                            setDurationAndFees(e.target.value);
                          }}
                          value={formData.duration}
                        >
                          <option selected>Select Duration</option>
                          {coursedata &&
                            coursedata.duration.map((item, index) => {
                              return (
                                <option key={index} value={item}>
                                  {item}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    {fees && (
                      <div className="text-end">
                        <span className="text-success">
                          Fees : {fees && fees.actual} <br />
                          {/* Registeration Fee : {coursedata?.registration} <br /> */}
                          {/* Remaining Fee :{" "} */}
                          {/* {fees.actual - coursedata.registration} */}
                          {/* {fees.actual} */}
                        </span>
                      </div>
                    )}
                    <div className="form-group mb-3">
                      <label className="form-label">
                        How Did You Hear About us?
                      </label>
                      {/* <input
                        type="text"
                        className="form-control form-control-lg"
                        value={formData.hear_about_us}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            hear_about_us: e.target.value,
                          });
                        }}
                      /> */}
                      <select
                        className="form-select form-select-lg mb-3"
                        style={{ background: "transparent" }}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            hear_about_us: e.target.value,
                          });
                        }}
                        value={formData.hear_about_us}
                      >
                        <option selected>Select</option>
                        <option value="Friend">Friends</option>
                        <option value="Social Media">Social Media</option>
                        <option value="College">College</option>
                        <option value="Others">Others</option>
                      </select>
                    </div>
                    <div className="row mb-3">
                      {/* <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            Discount Coupon Code
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            // value={formData.discount_coupon}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                // discount_coupon: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div> */}
                      <div className="col-12 col-md-6">
                        <div className="form-group ">
                          <label className="form-label">Any Comment ?</label>
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            value={formData.any_comment}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                any_comment: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">Reference Name</label>
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            value={formData.reference_name}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                reference_name: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-dark me-2 btn-lg mt-4 rounded-0"
                    onClick={() => setStep(1)}
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary btn-lg mt-4 rounded-0"
                  >
                    Register
                  </button>
                </>
              )}
            </form>

          </div>
        </div>
        {showSuccessPopup && <SuccessPopup onClose={() => setShowSuccessPopup(false)} />}

        {/* <div className="col-12 col-md-4 pe-5 d-flex justify-content-start">
  <a href="https://rzp.io/l/LsgD4vYZEM" className="w-100">
    <img
      src="assets/For_First_Year_Student.png"
      alt="Register"
      className="img-fluid w-100 h-75"
    />

    <img 
      src="https://www.netcomedu.in/public/images/courses/new-gif.gif" 
      width="50px" 
      className="img-responsive" 
      draggable="false"
    />

    <h4 className="col-20 col-md-20 pe-0 d-flex justify-content-start">Special Offer For First Year Student Register Now !!</h4>

    <button type="submit" className="btn btn-primary btn-lg mt-4 rounded-3">Register Now</button>
  </a>
</div> */}


        <div className="col-12 col-md-4 pe-5 d-none d-md-flex justify-content-start">

          <img
            src="assets/register_img.png"
            // src="assets\For_First_Year_Student.png"
            alt="Register"
            className="img-fluid w-100 h-75 "
          />

        </div>
      </div>
    </section>
  );
}

export default Register;
