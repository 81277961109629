// import React, { useEffect } from "react";
// import ScrollIntoView from "react-scroll-into-view";

// const Popup = ({ onClose }) => {
//   // Disable background scroll when popup is open
//   useEffect(() => {
//     document.body.style.overflow = "hidden";
//     return () => (document.body.style.overflow = "auto");
//   }, []);

//   return (
//     <div className="popup-overlay">
//       <div className="popup-content">
//         <button className="close-btn" onClick={onClose}>×</button>
//         <h2 color="red">Apply Now for Our Free Internship Program 2025!</h2>
//         <p>Limited Seats Available. <br/>Valid till February 14, 2025.</p>
  
//         <ScrollIntoView
//             selector="#formRegister"
//             className="d-none d-md-block"
//           >
//             <button className="btn btn-primary btn-lg my-auto p-2" onClick={onClose}>
//             Apply Now
//             </button>
//           </ScrollIntoView>
//       </div>

//       {/* Popup Styles */}
//       <style jsx>{`
//         .popup-overlay {
//           position: fixed;
//           top: 0;
//           left: 0;
//           width: 100%;
//           height: 100%;
//           background: rgba(0, 0, 0, 0.7);
//           display: flex;
//           justify-content: center;
//           align-items: center;
//           z-index: 1000;
//         }

//         .popup-content {
//           background: white;
//           padding: 30px;
//           border-radius: 10px;
//           text-align: center;
//           max-width: 400px;
//           box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
//           position: relative;
//           animation: fadeIn 0.5s ease-in-out;
//         }

//         .close-btn {
//           position: absolute;
//           top: 10px;
//           right: 15px;
//           background: transparent;
//           border: none;
//           font-size: 20px;
//           cursor: pointer;
//         }

//         .apply-btn {
//           background-color: #007bff;
//           color: white;
//           border: none;
//           padding: 12px 20px;
//           border-radius: 5px;
//           cursor: pointer;
//           font-size: 16px;
//           margin-top: 15px;
//         }

//         .apply-btn:hover {
//           background-color: #0056b3;
//         }

//         @keyframes fadeIn {
//           from {
//             opacity: 0;
//             transform: scale(0.9);
//           }
//           to {
//             opacity: 1;
//             transform: scale(1);
//           }
//         }
//       `}</style>
//     </div>
//   );
// };

// export default Popup;

import React, { useEffect } from "react";
import ScrollIntoView from "react-scroll-into-view";

const Popup = ({ onClose }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "auto");
  }, []);

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button className="close-btn" onClick={onClose}>×</button>
        <h2 style={{ color: "#343a40", fontFamily: 'Poppins, sans-serif' }}>
          Free Internship Program 2025!🚀
        </h2>
        <p style={{ color: "#6c757d", fontFamily: 'Roboto, sans-serif' }}>
          <b>Limited Seats</b> Available. <br />
          Valid till <b>February 14, 2025.</b>
        </p>
{/* 
        <ScrollIntoView selector="#formRegister" className="d-none d-md-block">
          <button
            className="btn btn-primary btn-lg my-auto p-3 apply-btn"
            onClick={onClose}
            style={{backgroundColor: "#007bff", fontFamily: 'Roboto, sans-serif'}} // Use inline styles for button
          >
            Apply Now ➡
          </button>
        </ScrollIntoView> */}

<ScrollIntoView selector="#formRegister"> {/* Remove the Bootstrap classes */}
  <button
    className="btn btn-primary btn-lg my-auto p-3 apply-btn"
    onClick={onClose}
    style={{ backgroundColor: "#007bff", fontFamily: 'Roboto, sans-serif' }}
  >
    Apply Now ➡
  </button>
</ScrollIntoView>
      </div>

      <style jsx>{`
        @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&family=Roboto:wght@400;700&display=swap');

        .popup-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.8); /* Slightly darker backdrop */
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
          backdrop-filter: blur(5px); /* Add a subtle blur */
        }

        .popup-content {
          background: #fff; /* White background */
          padding: 40px; /* Increased padding */
          border-radius: 15px; /* More rounded corners */
          text-align: center;
          max-width: 500px; /* Wider popup */
          box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3); /* More prominent shadow */
          position: relative;
          animation: fadeIn 0.5s ease-in-out;
        }

        .close-btn {
          position: absolute;
          top: 15px;
          right: 20px;
          background: transparent;
          border: none;
          font-size: 24px; /* Larger close button */
          cursor: pointer;
          color: #6c757d; /* Gray close button */
        }


        .apply-btn {
          /* Button styles moved inline for better control */
          font-weight: 500; /* Slightly bolder font */
          transition: all 0.3s ease; /* Smooth hover effect */
        }

        .apply-btn:hover {
          background-color: #0056b3;
          transform: translateY(-2px); /* Lift up slightly on hover */
          box-shadow: 0 4px 10px rgba(0,0,0,0.2);
        }

        @keyframes fadeIn {
          from {
            opacity: 0;
            transform: translateY(-20px); /* Slide in from top */
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
          
      `}</style>
    </div>
  );
};

export default Popup;