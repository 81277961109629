import React from "react";
import Swiper from "swiper";
import "swiper/css";
import DefaultSlider from "./Swiper/DefaultSlider";
import { SwiperSlide } from "swiper/react";

function Gallery({ images }) {
  return (
    <div className="GallerySection mt-5">
      <div className="">
        <h3 className="text-center  d-block d-md-none">Gallery</h3>
        <div className="container text-center d-none d-lg-block">
          {images.map((image, index) => {
            return (
              <React.Fragment key={index}>
                <div className="d-inline-block m-2" style={{ width: "18%" }}>
                  <img
                    key={index}
                    src={image}
                    alt="gallery"
                    className="img-fluid"
                  />
                </div>
                {index === 10 && (
                  <div
                    className="d-none d-lg-inline-block m-2 fs-1 text-center "
                    style={{
                      width: "56.4%",
                      font: "50px Roboto, sans-serif",
                      fontWeight: "700",
                    }}
                  >
                    Impactful Engaging Interactions with our Learners
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </div>
        {/* Swiper for mobile screens */}
        <div>
          <DefaultSlider
            loop={true}
            autoplay={{ delay: 1000, disableOnInteraction: false }}
            speed={10000}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 50,
              },
              1200: {
                slidesPerView: 6,
                spaceBetween: 60,
              },
            }}
            scrollbar={{ draggable: true }}
          >
            {images.map((image, index) => {
              return (
                <SwiperSlide
                  className="d-flex d-lg-none d-block justify-content-center"
                  key={index}
                >
                  <img
                    key={index}
                    src={image}
                    alt="gallery"
                    className="img-fluid w-100 p-3 rounded-5"
                  />
                </SwiperSlide>
              );
            })}
          </DefaultSlider>
        </div>
      </div>
    </div>
  );
}

export default Gallery;
