import React from "react";
import DefaultSlider from "./Swiper/DefaultSlider";
import { SwiperSlide } from "swiper/react";

function GetCertificate({ images }) {
  return (
    <section className="pt-5 text-white" style={{ background: "#2e3138" }}>
      <div className="container-fluid">
        <h3 className="text-center mt-3">Get Certificates</h3>
        <div className="row m-0 d-flex justify-content-center">
          {" "}
          {/* Changed justify-content-between to justify-content-center */}
          <div className="col-12 col-md-7 padding-certi">
            <p
              style={{ font: "22px 'Source Sans Pro', sans-serif" }}
              className="lh-lg text-start"
            >
              Upon successfully completing the internship, participants will receive two certificates that recognize their dedication and the skills they've developed:
            </p>
            <p  style={{ font: "18px 'Source Sans Pro', sans-serif" }}
              className=" text-start"><li><b>Certificate of Internship:</b> Awarded by Learn and Build (LnB), this certificate acknowledges your active participation and accomplishments during the internship program.</li>
              </p>

            <p style={{ font: "18px 'Source Sans Pro', sans-serif" }}
              className="text-start">
              <li> <b>Certificate by Industry Partner :</b>Provided by the industry Partner, this certificate validates the practical skills and knowledge you've acquired, enhancing your professional credentials.</li>

              </p>

              <p>These certifications not only attest to your achievements but also demonstrate your commitment to continuous learning and professional development.</p>

            <ul className="ms-0 ps-0" style={{ listStyle: "none" }}>
              {/* <li className="ListStyle  d-flex align-items-center py-2">
                <img
                  src="assets/list-style-icon.png"
                  className="me-2"
                  alt="list-style"
                />
                <span>Program Completion Certificate</span>
              </li> */}
              {/* <li className="ListStyle d-flex align-items-center py-2">
                <img
                  src="assets/list-style-icon.png"
                  className="me-2"
                  alt="list-style"
                />
                <span>Project Completion Certificate</span>
              </li> */}
              {/* <li className="ListStyle d-flex align-items-center py-2">
                <img
                  src="assets/list-style-icon.png"
                  className="me-2"
                  alt="list-style"
                />
                <span>Certificate of Internship</span>
              </li> */}
              {/* <li className="ListStyle d-flex align-items-center py-2">
                <img
                  src="assets/list-style-icon.png"
                  className="me-2"
                  alt="list-style"
                />
                <span>Letter of Recommendation</span>
              </li> */}
            </ul>
            <h3 className="text-uppercase text-start mt-5">
              take the <span className="FirstStep">first step</span>
              <br />
              towards a successful career
            </h3>
            <button className="btn btn-primary btn-lg mt-5">Join Now</button>
          </div>
          <div className="col-12 text-center col-md-5">
            {" "}
            {/* Removed d-flex justify-content-center */}
            <div>
              <DefaultSlider
                loop={true}
                autoplay={{ delay: 1000, disableOnInteraction: false }}
                speed={10000}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 30,
                  },
                  768: {
                    slidesPerView: 1,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 1,
                    spaceBetween: 50,
                  },
                  1200: {
                    slidesPerView: 1,
                    spaceBetween: 60,
                  },
                }}
                scrollbar={{ draggable: true }}
                className="swiper-container-centered align-items-center"
              >
                {images.map((image, index) => (
                  <SwiperSlide
                    className="d-flex justify-content-center align-items-center "
                    key={index}
                  >
                    <img
                      key={index}
                      src={image}
                      alt="gallery"
                      className="img-fluid imgCerti align-self-center p-3 my-5 "
                    />
                  </SwiperSlide>
                ))}
              </DefaultSlider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GetCertificate;
