import React from "react";

function FAQ() {
  return (
    <div className="row m-0 pt-5" id="FAQs">
      <h3 className="text-center py-4 mt-5">Frequently Asked Questions</h3>
      <div className="col-12 col-md-6 text-center">
        <img src="assets/faq.png" alt="FAQ" className="img-fluid w-75 " />
      </div>
      <div className="col-12 col-md-6">
        <div className="accordion px-3 " id="accordionExample">
          <div className="accordion-item border-0 mt-5 mb-3 shadow ">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                <h5>
                Why should you join the Learn and Build Free Summer Internship 2025 Program?
                </h5>
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              {/* <div className="accordion-body"> */}
              <div className="accordion-body text-center">
                <p>

                <li>The Free Summer Internship offers <b>self-paced learning, weekly live interaction sessions, assessments and assignments</b>, and <b>project development</b> opportunities.</li> <br/>
                <li>It's a <b>technology-based program</b> designed to enhance practical skills.</li><br/>
                <li>Limited seats are available <b>(only 100 spots)</b>, making it a competitive and valuable learning experience.</li><br/>

                </p>
              </div>
            </div>
          </div>

          <div className="accordion-item border-0 mt-5 mb-3 shadow">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <h5>
                What is the Eligibility criteria for the free summer internship 2025?
                </h5>
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p>
                  <li>Anyone interested in coding, programming, or technology-related fields can apply.</li><br/>
                  <li>Selection for the free internship is based on a <b>Technology-Based Assessment</b> to ensure the best candidates are chosen.</li>
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item border-0 mt-5 mb-3 shadow">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <h5>When will the Technology-Based Assessment be conducted?</h5>
              </button>
            </h2>
            <div
              id="collapseThree"
              className="accordion collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p>
                  <li>The Technology-Based Assessment is set to take place between February 22 -  27, 2025. Detailed instructions and additional information will be emailed to all registered students as the assessment dates approachli</li>
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item border-0 mt-5 mb-3 shadow">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                <h5>
                How will this Project based Summer Internship Program benefit me?
                </h5>
              </button>
            </h2>
            <div
              id="collapseFour"
              className="accordion collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p>
                <li>You will work on <b>real-world projects</b> that enhance your practical skills.</li><br/>
                <li>You will receive <b>assignments and assessments</b> to reinforce learning.</li><br/>
                <li>It includes <b>self-paced learning and weekly live sessions</b>, ensuring flexibility while maintaining structured guidance.</li><br/>
                <li>Completing the internship will help build your <b>resume and industry experience.</b></li><br/>
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item border-0 mt-5 mb-3 shadow">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                <h5>
                What is the duration of the Free SITP?
                </h5>
              </button>
            </h2>
            <div
              id="collapseFive"
              className="accordion collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
               <p><li>
               The Free Summer Internship Program lasts <b>45 days.</b></li><br/></p>
              </div>
            </div>
          </div>
          <div className="accordion-item border-0 mt-5 mb-3 shadow">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSix"
                aria-expanded="false"
                aria-controls="collapseSix"
              >
                <h5>
                Is there any hidden fee for the Free SITP?
                </h5>
              </button>
            </h2>
            <div
              id="collapseSix"
              className="accordion collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
               <p>
                <li>The program is <b>completely free</b>, with no hidden fees mentioned in the document.</li><br/>
               </p>
              </div>
            </div>
          </div>
          <div className="accordion-item border-0 mt-5 mb-3 shadow">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseseven"
                aria-expanded="false"
                aria-controls="collapseSeven"
              >
                <h5>
                What certifications will I receive after completing the internship?
                </h5>
              </button>
            </h2>
            <div
              id="collapseseven"
              className="accordion collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p><li>Upon successful completion of the internship, you will be awarded two certifications: one from Learn and Build (LnB) and another from an industry partner. These certificates serve to validate the skills and knowledge you've acquired, adding significant value to your professional profile.
                </li><br/></p>
              </div>
            </div>
          </div>
          <div className="accordion-item border-0 mt-5 mb-3 shadow">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseEight"
                aria-expanded="false"
                aria-controls="collapseEight"
              >
                <h5>
                What are the Weekly Interaction Sessions?
                </h5>
              </button>
            </h2>
            <div
              id="collapseEight"
              className="accordion collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p><li>Weekly Interaction Sessions are live meetings where you can engage directly with industry experts and mentors. These sessions provide a platform to clarify doubts, discuss key concepts, and gain deeper insights, ensuring you stay connected and well-supported throughout your learning journey.
                </li><br/></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
